import queryString from "query-string";
import { IFiltersObject } from "../store/slices/productSlice";

// export const isFutureDate = (date: string | undefined): boolean => {
//   if (date === undefined) return false;
//   const convertedDate = new Date(date);
//   const now = new Date();
//   return convertedDate > now;
// };

export const isFutureDate = (date: string | undefined): boolean => {
  if (!date) return false;

  const parts = date.split("-");

  if (parts.length !== 3) return false;

  const day = parseInt(parts[0], 10); // Day
  const month = parseInt(parts[1], 10) - 1; // Month (0-based)
  const year = parseInt(parts[2], 10); // Year

  const convertedDate = new Date(year, month, day);

  if (isNaN(convertedDate.getTime())) return false;

  const now = new Date();
  return convertedDate > now;
};

export const calculateMinMaxPrice = (minValue: number, maxValue: number) => {
  const newMin = Math.max(0, Math.min(minValue, maxValue));
  const newMax = Math.min(20000, Math.max(minValue, maxValue));
  return {
    minPrice: newMin,
    maxPrice: newMax,
  };
};

export const deserializeFilterUrlParams = (searchParams: URLSearchParams) => {
  const brandIds = searchParams.get("brands")?.split(",") || [];
  const productTypeIds = searchParams.get("productTypes")?.split(",") || [];
  const minPrice = parseInt(searchParams.get("minPrice") || "", 10) || 0;
  const maxPrice = parseInt(searchParams.get("maxPrice") || "", 10) || 20000;
  const comingSoon = searchParams.get("comingSoon") === "true";
  const onSale = searchParams.get("onSale") === "true";
  const newProducts = searchParams.get("newProducts") === "true";
  return {
    brandIds,
    productTypeIds,
    minPrice,
    maxPrice,
    comingSoon,
    onSale,
    newProducts,
  };
};

export const timeUntil = (date: string | undefined): number => {
  if (date === undefined) return 0;
  const currDate = new Date();

  const parts = date.split("-");

  if (parts.length !== 3) return 0;

  const day = parseInt(parts[0], 10); // Day
  const month = parseInt(parts[1], 10) - 1; // Month (0-based)
  const year = parseInt(parts[2], 10); // Year

  const givenDate = new Date(year, month, day);

  const differenceInMs = givenDate.getTime() - currDate.getTime();

  return Math.floor(differenceInMs / 1000);
};
