import {
  BrandData,
  ProductData,
  ProductTypeData,
  SlideshowData,
} from "./../../videomobileApi/api";
import {
  getBrands,
  getProducts,
  getProductTypes,
  getSlideshows,
} from "../asyncThunk/productThunk";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum Status {
  Idle,
  Pending,
  Fulfilled,
  Failed,
}

interface IMultipleDataLoadable<T> {
  list: T[];
  dataState: Status;
  error?: any;
}

// interface ISingleDataLoadable<T> {
//   data: T;
//   dataState: Status;
//   error?: any;
// }

interface IFilter<T> {
  name: string;
  isOpen: boolean;
  data: T;
}

export interface IBrandFilterData {
  isChecked: boolean;
  brand: BrandData;
}

export interface IProductTypeFilterData {
  isChecked: boolean;
  productType: ProductTypeData;
}

export interface IPriceFilterData {
  minPrice: number;
  maxPrice: number;
}

export interface IFiltersObject {
  brand: IFilter<IBrandFilterData[]>;
  price: IFilter<IPriceFilterData>;
  onSale: IFilter<boolean>;
  productType: IFilter<IProductTypeFilterData[]>;
  comingSoon: IFilter<boolean>;
  newProducts: IFilter<boolean>;
}

export interface IProductInfoMap {
  [productId: string]: ProductData;
}

export interface IBrandInfoMap {
  [brandName: string]: BrandData;
}

interface IState {
  products: IMultipleDataLoadable<ProductData>;
  productInfoMap: IProductInfoMap;
  brands: IMultipleDataLoadable<BrandData>;
  brandsInfoMap: IBrandInfoMap;
  slideshows: IMultipleDataLoadable<SlideshowData>;
  filters: IFiltersObject;
  productTypes: IMultipleDataLoadable<ProductTypeData>;
}

const initialState: IState = {
  products: { list: [], dataState: Status.Idle },
  productInfoMap: {},
  brands: { list: [], dataState: Status.Idle },
  brandsInfoMap: {},
  slideshows: { list: [], dataState: Status.Idle },
  filters: {
    brand: {
      name: "Brand",
      isOpen: true,
      data: [],
    },
    price: {
      name: "Price",
      isOpen: true,
      data: {
        minPrice: 0,
        maxPrice: 20000,
      },
    },
    onSale: { name: "On Sale", isOpen: false, data: false },
    productType: {
      name: "Product Type",
      isOpen: true,
      data: [],
    },
    comingSoon: {
      name: "Coming Soon",
      isOpen: true,
      data: false,
    },
    newProducts: {
      name: "New Products",
      isOpen: true,
      data: false,
    },
  },
  productTypes: { list: [], dataState: Status.Idle },
};

const ProductSlice = createSlice({
  name: "Product",
  initialState,
  reducers: {
    openOrCloseFilter(state, action: PayloadAction<keyof IFiltersObject>) {
      const filterType = action.payload;
      if (state.filters[filterType]) {
        state.filters[filterType].isOpen = !state.filters[filterType].isOpen;
      }
    },
    checkComingSoonFilter(state, action: PayloadAction<boolean>) {
      state.filters.comingSoon.data = action.payload;
    },
    checkNewProductsFilter(state, action: PayloadAction<boolean>) {
      state.filters.newProducts.data = action.payload;
    },
    checkOnSaleFilter(state, action: PayloadAction<boolean>) {
      state.filters.onSale.data = action.payload;
    },
    checkBrandFilter(
      state,
      action: PayloadAction<{ id: string; isChecked: boolean }>
    ) {
      state.filters.brand.data = state.filters.brand.data.map((brandFilter) => {
        if (brandFilter.brand.id === action.payload.id) {
          brandFilter = { ...brandFilter, isChecked: action.payload.isChecked };
        }
        return brandFilter;
      });
    },
    changePriceFilter(state, action: PayloadAction<IPriceFilterData>) {
      state.filters.price.data = action.payload;
    },
    checkProductType(state, action: PayloadAction<IProductTypeFilterData>) {
      state.filters.productType.data = state.filters.productType.data.map(
        (data) => {
          if (data.productType.id === action.payload.productType.id) {
            return (data = { ...data, isChecked: action.payload.isChecked });
          }
          return data;
        }
      );
    },
    resetFilters(state) {
      state.filters.brand.data = state.filters.brand.data.map(
        (brandFilter) => (brandFilter = { ...brandFilter, isChecked: false })
      );
      state.filters.productType.data = state.filters.productType.data.map(
        (productTypeFilter) =>
          (productTypeFilter = { ...productTypeFilter, isChecked: false })
      );
      state.filters.comingSoon.data = false;
      state.filters.newProducts.data = false;
      state.filters.onSale.data = false;
      state.filters.price.data.minPrice = 0;
      state.filters.price.data.maxPrice = 20000;
    },
    setFilters(
      state,
      action: PayloadAction<{
        checkedBrands: string[];
        minPrice: number;
        maxPrice: number;
        checkedProductTypes: string[];
        comingSoon: boolean;
        onSale: boolean;
        newProducts: boolean;
      }>
    ) {
      const filtersToBeSet = action.payload;
      state.filters.brand.data = state.filters.brand.data.map((brandFilter) => {
        return {
          ...brandFilter,
          isChecked: filtersToBeSet.checkedBrands.includes(
            brandFilter.brand.id
          ),
        };
      });

      state.filters.price.data = {
        ...state.filters.price.data,
        minPrice: filtersToBeSet.minPrice,
        maxPrice: filtersToBeSet.maxPrice,
      };

      state.filters.productType.data = state.filters.productType.data.map(
        (productTypeFilter) => {
          return {
            ...productTypeFilter,
            isChecked: filtersToBeSet.checkedProductTypes.includes(
              productTypeFilter.productType.id
            ),
          };
        }
      );

      state.filters.comingSoon.data = filtersToBeSet.comingSoon;
      state.filters.newProducts.data = filtersToBeSet.newProducts;
      state.filters.onSale.data = filtersToBeSet.onSale;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.fulfilled, (state, action) => {
        state.products.list = action.payload ?? ([] as ProductData[]);
        state.products.dataState = Status.Fulfilled;
        state.products.list.forEach(
          (product) => (state.productInfoMap[product.id] = product)
        );
      })
      .addCase(getProducts.pending, (state) => {
        state.products.dataState = Status.Pending;
      })
      .addCase(getProducts.rejected, (state) => {
        console.log("problem");
        state.products.dataState = Status.Failed;
        state.products.list = [];
        state.productInfoMap = {};
      })
      .addCase(getBrands.fulfilled, (state, action) => {
        state.brands.dataState = Status.Fulfilled;
        state.brands.list = action.payload ?? ([] as BrandData[]);
        state.brands.list.forEach((brand) => {
          state.brandsInfoMap[brand.id] = brand;
        });
        state.filters.brand.data = state.brands.list.map((brand) => {
          return {
            brand: brand,
            isChecked: false,
          };
        });
      })
      .addCase(getBrands.pending, (state) => {
        state.brands.dataState = Status.Pending;
      })
      .addCase(getBrands.rejected, (state) => {
        state.brands.dataState = Status.Failed;
      })
      .addCase(getSlideshows.fulfilled, (state, action) => {
        state.slideshows.dataState = Status.Fulfilled;
        if (action.payload) {
          state.slideshows.list = action.payload.sort(
            (a, b) => a.index - b.index
          );
        }
      })
      .addCase(getSlideshows.pending, (state) => {
        state.slideshows.dataState = Status.Pending;
      })
      .addCase(getSlideshows.rejected, (state) => {
        state.slideshows.dataState = Status.Failed;
      })
      .addCase(getProductTypes.fulfilled, (state, action) => {
        if (action.payload) {
          state.productTypes.list = action.payload;
          state.filters.productType.data = state.productTypes.list.map(
            (productType) => {
              return { productType: productType, isChecked: false };
            }
          );
          state.productTypes.dataState = Status.Fulfilled;
        }
      })
      .addCase(getProductTypes.pending, (state) => {
        state.productTypes.dataState = Status.Pending;
      })
      .addCase(getProductTypes.rejected, (state) => {
        state.productTypes.dataState = Status.Failed;
      });
  },
});

export default ProductSlice.reducer;
export const {
  openOrCloseFilter,
  checkComingSoonFilter,
  checkNewProductsFilter,
  checkOnSaleFilter,
  checkBrandFilter,
  changePriceFilter,
  checkProductType,
  resetFilters,
  setFilters,
} = ProductSlice.actions;
