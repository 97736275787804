import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Homepage } from "./components";
import { NavBar } from "./components";
import { routesList } from "./constants";
import { Box, Container } from "@mui/material";
import { Footer } from "./components/Footer";
import { NewSingleProductPage } from "./components/SingleProductPage";

function AppContent() {
  return (
    <Container
      maxWidth="xl"
      sx={{
        background: "#fff",
        top: "0px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <NavBar />
      <Box flexGrow={1}>
        <Routes>
          <Route index element={<Homepage />} />
          <Route
            path={routesList.singleProduct}
            element={<NewSingleProductPage />}
          />
          <Route path="*" element={<Homepage />} />
        </Routes>
      </Box>
      <Footer />
    </Container>
  );
}

export default AppContent;
