import DOMPurify from "dompurify";
import { useMemo } from "react";

import "./ProductDescription.css";

interface IDescriptionProps {
  htmlContent: string;
}

export const ProductDescription = ({ htmlContent }: IDescriptionProps) => {
  const sanitizedDescription = useMemo(() => {
    return DOMPurify.sanitize(htmlContent);
  }, [htmlContent]);
  return (
    <div
      dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
      style={{
        whiteSpace: "pre-line",
      }}
    />
  );
};
