import { Slideshow } from "../Slideshow";
import { Stack } from "@mui/material";
import { ProductPage } from "../ProductPage";
import { useRef } from "react";

import { useRequestDataIfNeeded } from "../../hooks/useRequestDataIfNeeded";

export const Homepage = () => {
  useRequestDataIfNeeded();

  const productPageRef = useRef<HTMLDivElement>(null);

  const scrollProductPageIntoView = () => {
    productPageRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <Stack spacing={1}>
      <Slideshow scrollProductPageIntoView={scrollProductPageIntoView} />

      <ProductPage ref={productPageRef} />
    </Stack>
  );
};
