import { ProductData } from "../../../videomobileApi";
import { isFutureDate, timeUntil } from "../../../utility/utility";
import { alpha, Box, Typography, useTheme } from "@mui/material";
import { CountdownTimer } from "../CountdownTimer";
import { useMemo } from "react";

interface IProductTopBannerProps {
  product: ProductData;
}

export const ProductTopBanner = ({ product }: IProductTopBannerProps) => {
  const theme = useTheme();

  const isComingSoon = useMemo(() => {
    return isFutureDate(product.comingSoonUntil);
  }, [product.comingSoonUntil]);

  const isDiscount = useMemo(() => {
    return isFutureDate(product.discountUntil) && product.discountPrice;
  }, [product.discountPrice, product.discountUntil]);

  const isNew = useMemo(() => {
    return isFutureDate(product.newProductUntil);
  }, [product.newProductUntil]);

  const topBanner = () => {
    if (isComingSoon || isDiscount || isNew) {
      return (
        <Box
          bgcolor={
            isComingSoon
              ? "rgba(0,0,0,0.6)"
              : isNew
                ? alpha(theme.palette.primary.light, 0.6)
                : "rgba(255,0,0,0.6)"
          }
          sx={{
            zIndex: 2,
            textAlign: "center",
            color: "white",
            width: "100%",
            left: 0,
            top: 0,
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            display: "flex",
            p: 1,
            height: "30px",
          }}
        >
          {isComingSoon ? (
            <Typography variant="body2" sx={{ lineHeight: "20px" }}>
              Coming Soon
            </Typography>
          ) : isNew ? (
            <Typography variant="body2">New Product</Typography>
          ) : (
            <CountdownTimer initialSeconds={timeUntil(product.discountUntil)} />
          )}
        </Box>
      );
    }
  };
  return topBanner();
};
