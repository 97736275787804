import { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/store";
import { Status } from "../store/slices/productSlice";
import {
  getBrands,
  getProducts,
  getProductTypes,
  getSlideshows,
} from "../store/asyncThunk/productThunk";

const TIMEOUT_TIME = 1000;
const MAX_REQUEST_COUNT = 5;

export const useRequestDataIfNeeded = (
  dataToRequest:
    | ("products" | "brands" | "slideshows" | "productTypes")[]
    | undefined = undefined
) => {
  const products = useAppSelector((state) => state.products);
  const getProductsRequestCount = useRef<number>(0);
  const brands = useAppSelector((state) => state.brands);
  const getBrandsAlreadyRan = useRef<boolean>(false);
  const getBrandsRequestCount = useRef<number>(0);
  const slideshows = useAppSelector((state) => state.slideshows);
  const getSlideshowsRequestCount = useRef<number>(0);
  const productTypes = useAppSelector((state) => state.productTypes);
  const getProductTypesAlreadyRan = useRef<boolean>(false);
  const getProductTypesRequestCount = useRef<number>(0);
  useState<boolean>(false);
  const dispatch = useAppDispatch();

  const requestProducts = useMemo(() => {
    return !dataToRequest || dataToRequest.includes("products");
  }, [dataToRequest]);

  const requestBrands = useMemo(() => {
    return !dataToRequest || dataToRequest.includes("brands");
  }, [dataToRequest]);

  const requestSlideshows = useMemo(() => {
    return !dataToRequest || dataToRequest.includes("slideshows");
  }, [dataToRequest]);

  const requestProductTypes = useMemo(() => {
    return !dataToRequest || dataToRequest.includes("productTypes");
  }, [dataToRequest]);

  useEffect(() => {
    if (getProductTypesRequestCount.current > MAX_REQUEST_COUNT) return;
    if (
      requestProducts &&
      products.dataState !== Status.Fulfilled &&
      products.dataState !== Status.Pending
    ) {
      if (products.dataState === Status.Failed) {
        const timeout = setTimeout(() => {
          dispatch(getProducts());
        }, TIMEOUT_TIME);
        getProductsRequestCount.current += 1;
        return () => clearTimeout(timeout);
      } else {
        dispatch(getProducts());
      }
    }
  }, [dispatch, products.dataState, requestProducts]);

  useEffect(() => {
    if (getBrandsRequestCount.current > MAX_REQUEST_COUNT) return;
    if (
      requestBrands &&
      brands.dataState !== Status.Fulfilled &&
      brands.dataState !== Status.Pending &&
      (!getBrandsAlreadyRan.current || brands.dataState === Status.Failed)
    ) {
      if (brands.dataState === Status.Failed) {
        getBrandsRequestCount.current += 1;
        const timeout = setTimeout(() => {
          dispatch(getBrands());
        }, TIMEOUT_TIME);
        return () => clearTimeout(timeout);
      } else if (!getBrandsAlreadyRan.current) {
        dispatch(getBrands());
        getBrandsAlreadyRan.current = true;
      }
    }
  }, [brands.dataState, dispatch, getBrandsAlreadyRan, requestBrands]);

  useEffect(() => {
    if (getSlideshowsRequestCount.current > MAX_REQUEST_COUNT) return;
    if (
      requestSlideshows &&
      slideshows.dataState !== Status.Fulfilled &&
      slideshows.dataState !== Status.Pending
    ) {
      if (slideshows.dataState === Status.Failed) {
        getSlideshowsRequestCount.current += 1;
        const timeout = setTimeout(() => {
          dispatch(getSlideshows());
        }, TIMEOUT_TIME);
        return () => clearTimeout(timeout);
      } else {
        dispatch(getSlideshows());
      }
    }
  }, [dispatch, requestSlideshows, slideshows.dataState]);

  useEffect(() => {
    if (getProductTypesRequestCount.current > MAX_REQUEST_COUNT) return;
    if (
      requestProductTypes &&
      productTypes.dataState !== Status.Fulfilled &&
      productTypes.dataState !== Status.Pending &&
      (!getProductTypesAlreadyRan.current ||
        productTypes.dataState === Status.Failed)
    ) {
      if (productTypes.dataState === Status.Failed) {
        getProductTypesRequestCount.current += 1;
        const timeout = setTimeout(() => {
          dispatch(getProductTypes());
        }, TIMEOUT_TIME);
        return () => clearTimeout(timeout);
      } else if (!getProductTypesAlreadyRan.current) {
        dispatch(getProductTypes());
        getProductTypesAlreadyRan.current = true;
      }
    }
  }, [dispatch, productTypes.dataState, requestProductTypes]);
};
