import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { ProductData } from "../../videomobileApi";
import { isFutureDate } from "../../utility/utility";
import { useNavigate } from "react-router-dom";
import { ProductTopBanner } from "../Common";
import { useAppSelector } from "../../store/store";
import Carousel from "react-material-ui-carousel";

interface IProductProps {
  product: ProductData;
}

export const ProductCard = ({ product }: IProductProps) => {
  const brandInfoMap = useAppSelector((state) => state.brandsInfoMap);
  const nav = useNavigate();
  const theme = useTheme();
  const stockStatusInfo = () => {
    if (product.stock === 0) return { color: "red", status: "Out of Stock" };
    if (product.stock === 1)
      return {
        color: theme.palette.primary.main,
        status: "Last in Stock",
      };
    return { color: "green", status: "In Stock" };
  };

  const priceInfo = () => {
    return (
      <>
        {isFutureDate(product.discountUntil) && product.discountPrice ? (
          <>
            <Typography
              variant="h6"
              component="div"
              color="error"
              sx={{
                mr: 1,
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              €{product.discountPrice?.toFixed(2)}
            </Typography>
            <Typography
              variant="body2"
              component="div"
              color="textSecondary"
              style={{ textDecoration: "line-through" }}
            >
              €{product.price?.toFixed(2)}
            </Typography>
          </>
        ) : (
          <Typography
            variant="h6"
            component="div"
            fontWeight={"bold"}
            sx={{ fontWeight: "bold", fontSize: "15px" }}
          >
            €{product.price?.toFixed(2)}
          </Typography>
        )}
      </>
    );
  };

  return (
    <Card
      onClick={(e) => {
        nav(`/${product.id}`);
      }}
      sx={{
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        cursor: "pointer",
      }}
      variant="outlined"
    >
      <ProductTopBanner product={product} />
      <Carousel
        autoPlay={false}
        indicators={false}
        swipe
        navButtonsAlwaysInvisible={
          product.picturesUrl ? product.picturesUrl.length <= 1 : true
        }
        duration={0}
        sx={{
          width: "100%",
          aspectRatio: "1/1",
          transitionDuration: "0ms",
        }}
      >
        {product.picturesUrl?.map((url, index) => (
          <CardMedia
            key={index}
            component="img"
            sx={{
              aspectRatio: "1/1",
              width: "100%",
            }}
            image={url}
            alt={`Product Image ${index + 1}`}
          />
        ))}
      </Carousel>
      <CardContent>
        <Tooltip title={product.name}>
          <Typography
            variant="h6"
            component="div"
            fontWeight="bold"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              height: "30px", // Adjust based on WebkitLineClamp
              lineHeight: "30px",
              whiteSpace: "nowrap",
              fontSize: "15px",
            }}
          >
            {product.name}
          </Typography>
        </Tooltip>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            mt: 1,
          }}
        >
          {product.price === 0 ? (
            <Typography
              variant="h6"
              component="div"
              fontWeight={"bold"}
              sx={{ fontWeight: "bold", fontSize: "15px" }}
            >
              Learn More
            </Typography>
          ) : (
            priceInfo()
          )}
        </Box>
        {product.stock !== undefined && product.stock >= 0 && (
          <Typography
            variant="body2"
            color={stockStatusInfo().color}
            component={"span"}
            sx={{
              verticalAlign: "middle",
            }}
          >
            {stockStatusInfo().status}
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            aspectRatio: "3/1",
            mt: 2,
            overflow: "hidden",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {brandInfoMap[product.brandId ?? ""] && (
            <img
              src={brandInfoMap[product.brandId ?? ""].pictureUrl}
              alt="Brand cannot be displayed"
              style={{ width: "65%", aspectRatio: "3/1" }}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
