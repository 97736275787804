import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import TimerIcon from "@mui/icons-material/Timer";

interface CountdownTimerProps {
  initialSeconds: number;
}

export const CountdownTimer: React.FC<CountdownTimerProps> = ({
  initialSeconds,
}) => {
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds: number) => {
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${days}d ${hours}h ${minutes < 10 ? "0" : ""}${minutes}m ${
      remainingSeconds < 10 ? "0" : ""
    }${remainingSeconds}s`;
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <TimerIcon fontSize="small" sx={{ mr: "1px" }} />
      <Typography variant="body2">{formatTime(seconds)}</Typography>
    </Box>
  );
};
