import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../store/store";
import { routesList } from "../../constants";
import { Box, Divider, Grid2, Paper, Stack, Typography } from "@mui/material";
import { isFutureDate } from "../../utility/utility";
import { ProductData } from "../../videomobileApi";
import { ProductCard } from "../ProductCard";
import { ProductDescription, ProductTopBanner } from "../Common";
import { useRequestDataIfNeeded } from "../../hooks/useRequestDataIfNeeded";
import { theme } from "../../styles";
import { Status } from "../../store/slices/productSlice";
import Carousel from "react-material-ui-carousel";
import { useMobile } from "../../MobileProvider";

export const NewSingleProductPage = () => {
  const nav = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const carouselThumbnailContainerRef = useRef<HTMLDivElement | null>(null);
  const productInfoMap = useAppSelector((state) => state.productInfoMap);
  const products = useAppSelector((state) => state.products);
  const brandInfoMap = useAppSelector((state) => state.brandsInfoMap);
  const isMobile = useMobile();

  useRequestDataIfNeeded();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const product = useMemo(() => {
    return id ? productInfoMap[id] : undefined;
  }, [id, productInfoMap]);

  const brand = useMemo(() => {
    return brandInfoMap[product?.brandId ?? ""];
  }, [brandInfoMap, product?.brandId]);

  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);

  useEffect(() => {
    if (products.dataState === Status.Fulfilled && product === undefined) {
      nav(routesList.homepage);
    }
  }, [id, nav, product, productInfoMap, products.dataState]);

  const suggestedProducts = useMemo<ProductData[]>(() => {
    if (!product?.suggestions) return [];

    return product.suggestions.map((suggestion) => {
      return productInfoMap[suggestion];
    });
  }, [product?.suggestions, productInfoMap]);

  const onSelectedImageChanged = (index: number) => {
    setSelectedImageIndex(index);
    const thumbnailElement = document.getElementById(`thumbnail-${index}`);
    if (thumbnailElement && carouselThumbnailContainerRef.current) {
      const container = carouselThumbnailContainerRef.current;

      // Calculate the position of the thumbnail relative to the container
      const thumbnailRect = thumbnailElement.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      const isFullyVisible =
        thumbnailRect.left >= containerRect.left &&
        thumbnailRect.right <= containerRect.right;
      if (!isFullyVisible) {
        container.scrollLeft += thumbnailRect.left - containerRect.left;
      }
    }
  };

  const leftSide = () => {
    if (!product) return <></>;
    return (
      <Box sx={{ flex: "0 0 50%" }}>
        <Box
          sx={{
            position: isMobile ? "relative" : "sticky",
            top: isMobile ? "0px" : "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
            flexDirection: "column",
          }}
        >
          <Box
            sx={{ width: "80%", border: "1px solid rgba(0, 0, 0, 0.1)", mb: 3 }}
          >
            <Carousel
              sx={{
                width: "100%",
                aspectRatio: "1/1",
              }}
              autoPlay={false}
              navButtonsAlwaysInvisible={
                product.picturesUrl ? product.picturesUrl.length <= 1 : true
              }
              index={selectedImageIndex}
              onChange={(now) => {
                console.log(now);
                onSelectedImageChanged(now ?? 0);
              }}
            >
              {product.picturesUrl.map((picture, index) => (
                <Box key={picture} sx={{}}>
                  <ProductTopBanner product={product} />
                  <img
                    key={picture}
                    src={picture}
                    alt={`Product ${index}`}
                    style={{
                      width: "100%",
                      aspectRatio: "1/1",
                    }}
                    draggable={false}
                  />
                </Box>
              ))}
            </Carousel>
          </Box>
          <Box
            ref={carouselThumbnailContainerRef}
            sx={{
              height: "60px",
              maxWidth: isMobile ? "80vw" : "30vw",
              display: "flex",
              flexDirection: "row",
              overflowX: "auto",
              whiteSpace: "nowrap",
              flexWrap: "nowrap",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
              }}
            >
              {product.picturesUrl.length > 1 &&
                product.picturesUrl.map((picture, index) => {
                  return (
                    <Box
                      key={index}
                      id={`thumbnail-${index}`}
                      onClick={() => {
                        onSelectedImageChanged(index);
                      }}
                      sx={{
                        cursor: "pointer",
                        height: "100%",
                        aspectRatio: "1/1",
                        border:
                          selectedImageIndex === index
                            ? `2px solid ${theme.palette.primary.main}`
                            : "none",
                        opacity: selectedImageIndex === index ? 1 : 0.6,
                        transition: "border 0.3s, opacity 0.3s",
                        flexShrink: 0,
                        flexGrow: 0,
                        // p: 1,
                        boxSizing: "border-box",
                        // mx: "5px",
                      }}
                    >
                      <img
                        src={picture}
                        alt={`Thumbnail ${index + 1}`}
                        style={{
                          height: "100%",
                          aspectRatio: "1/1",
                          userSelect: "none",
                          display: "block",
                        }}
                        draggable={false}
                      />
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const rightSide = () => {
    if (!product) return <></>;
    return (
      <Box sx={{ flex: "0 0 50%", p: 2 }}>
        <ProductDescription htmlContent={product.description ?? ""} />
      </Box>
    );
  };

  const priceInfo = () => {
    if (!product) return <></>;
    const stockStatusInfo = () => {
      if (product.stock === 0) return { color: "red", status: "Out of Stock" };
      if (product.stock === 1)
        return {
          color: theme.palette.primary.main,
          status: "Last in Stock",
        };
      return { color: "green", status: "In Stock" };
    };
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: isMobile ? "100%" : "50%",
        }}
      >
        <Typography variant="h5" fontWeight={"bold"}>
          {product.name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            alignItems: "center",
            mt: 1,
          }}
        >
          {product.price > 0 && (
            <>
              {isFutureDate(product.discountUntil) && product.discountPrice ? (
                <>
                  <Typography
                    variant="h6"
                    component="div"
                    color="error"
                    fontWeight={"bold"}
                    mr={1}
                  >
                    €{product.discountPrice?.toFixed(2)}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="textSecondary"
                    style={{ textDecoration: "line-through" }}
                    mr={1}
                  >
                    €{product.price?.toFixed(2)}
                  </Typography>
                </>
              ) : (
                <Typography
                  variant="h6"
                  component="div"
                  fontWeight={"bold"}
                  mr={1}
                >
                  €{product.price?.toFixed(2)}
                </Typography>
              )}
              <Typography variant="body2" component="div" color={"grey"} mr={1}>
                (VAT included)
              </Typography>
            </>
          )}
          {product.stock !== undefined && product.stock >= 0 && (
            <Typography
              variant="body2"
              color={stockStatusInfo().color}
              component={"span"}
              sx={{
                verticalAlign: "middle",
              }}
            >
              {stockStatusInfo().status}
            </Typography>
          )}
        </Box>
        {isFutureDate(product.discountUntil) &&
          product.discountPrice &&
          product.price && (
            <Typography variant="body2" component="div" color="green">
              Instant Savings €{product.price - product.discountPrice}
            </Typography>
          )}
      </Box>
    );
  };

  const topSide = () => {
    if (!product) return <></>;
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: "5%",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        {priceInfo()}
        {brand && brand.pictureUrl && (
          <img
            src={brand.pictureUrl}
            alt="Brand could not be displayed."
            style={{
              width: isMobile ? "40%" : "20%",
              aspectRatio: "3/1",
              marginTop: isMobile ? 20 : 0,
            }}
          />
        )}
      </Box>
    );
  };

  const bottomSide = () => {
    return (
      <>
        {suggestedProducts.length > 0 && (
          <Box sx={{ width: "100%", px: "5%" }}>
            <Typography variant="h4" fontStyle={"bold"} my={2}>
              Other Suggestions
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                overflow: "auto",
                width: "100%",
                gap: 1,
              }}
            >
              {suggestedProducts.map((product) => (
                <Box
                  key={product.id}
                  sx={{
                    width: "150px",
                    overflow: "hidden",
                    flexShrink: 0,
                  }}
                >
                  <ProductCard product={product} />
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </>
    );
  };
  return (
    <Stack gap={2} mt={2}>
      {topSide()}
      <Divider />
      <Box
        sx={{
          minHeight: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        {leftSide()}
        {rightSide()}
      </Box>
      <Divider />
      {bottomSide()}
    </Stack>
  );
};
