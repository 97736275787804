import TextField from "@mui/material/TextField";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import Popper from "@mui/material/Popper";
import { useTheme, styled } from "@mui/material/styles";
import { VariableSizeList, ListChildComponentProps } from "react-window";
import Typography from "@mui/material/Typography";
import { ProductData } from "../../../videomobileApi";
import { useAppSelector } from "../../../store/store";
import {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  AutocompleteHighlightChangeReason,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useMobile } from "../../../MobileProvider";

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  const { key, ...optionProps } = dataSet[0];

  return (
    <Typography
      key={key}
      component="li"
      {...optionProps}
      noWrap
      style={inlineStyle}
    >
      {dataSet[1]}
    </Typography>
  );
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactElement<any>[] = [];
  (children as React.ReactElement<any>[]).forEach(
    (
      item: React.ReactElement<any> & { children?: React.ReactElement<any>[] }
    ) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactElement<any>) => {
    if (child.hasOwnProperty("group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

export const VirtualizedSearchBar = () => {
  const productInfoMap = useAppSelector((state) => state.productInfoMap);
  const products = useAppSelector((state) => state.products);
  const [selectedProductId, setSelectedProductId] =
    useState<ProductData | null>(null);
  const nav = useNavigate();
  const isMobile = useMobile();

  return (
    <Autocomplete
      id="searchBar"
      openOnFocus
      autoHighlight
      blurOnSelect
      sx={{
        width: "100%",
        height: "40px",
      }}
      options={products.list}
      getOptionLabel={(option) => {
        return option.name ? option.name : "";
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search Products"
          sx={{
            ".MuiInputBase-root": {
              height: "40px", // Set the height of the input container
              borderRadius: "4px",
            },
            // background: "red",
            ".MuiInputLabel-root": {
              left: "-5px", // Adjust the label position
            },
            ".MuiInputBase-input": {
              height: "100%", // Adjust the height of the input text area
              padding: "0 8px",
              color: isMobile ? "inherit" : "white",
            },
            ...(isMobile
              ? {}
              : {
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white", // Set the default border color to white
                    },
                    "&:hover fieldset": {
                      borderColor: "white", // Set the border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white", // Set the border color when focused
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "white", // Set label color to white
                  },
                }),
          }}
          slotProps={{
            input: {
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: isMobile ? "inherit" : "white" }} />
                </InputAdornment>
              ),
            },
          }}
        />
      )}
      renderOption={(props, option, state) =>
        [props, option.name, state.index] as React.ReactNode
      }
      slots={{
        popper: StyledPopper,
        listbox: ListboxComponent,
      }}
      value={selectedProductId}
      onChange={(_, newVal) => {
        console.log("onchange");
        setSelectedProductId(newVal);
        if (newVal && productInfoMap[newVal.id]) {
          nav(`/${newVal.id}`);
          setSelectedProductId(null);
        }
      }}
    />
  );
};
