import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import {
  Button,
  Divider,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import logo_140_160 from "../../assets/logo_140_160.png";
import { useNavigate } from "react-router-dom";
import { routesList } from "../../constants";

import { VirtualizedSearchBar } from "../Common";
import { useMobile } from "../../MobileProvider";
import MenuIcon from "@mui/icons-material/Menu";
import { Sidebar } from "../Sidebar";
import CallIcon from "@mui/icons-material/Call";

const logoStyle = {
  width: "auto",
  height: "55px",
  cursor: "pointer",
  margin: "1rem",
};

export const NavBar = () => {
  const [open, setOpen] = useState(false);
  const nav = useNavigate();
  const isMobile = useMobile();
  //contact us

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEmail = () => {
    window.location.href = "mailto:info@videomobile.al";
    handleClose();
  };

  const handlePhone = () => {
    window.location.href = "tel:+355676000808";
    handleClose();
  };

  const handleInstagram = () => {
    window.open("https://www.instagram.com/videomobile_al/", "_blank");
    handleClose();
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const drawer = () => {
    return (
      <Box>
        <Button
          variant="text"
          color="primary"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          sx={{ minWidth: "30px", p: "4px" }}
        >
          <MenuIcon />
        </Button>
        <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
          <Box
            sx={{
              minWidth: "40dvw",
              p: 2,
              backgroundColor: "background.paper",
              flexGrow: 1,
            }}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Sidebar />
            <Divider />
          </Box>
        </Drawer>
      </Box>
    );
  };

  return (
    <AppBar
      sx={{
        position: "sticky",
        background: "#2c2c2e",
        width: "100%",
        boxShadow: 0,
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          flexShrink: 0,
          boxShadow: `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`,
          height: 40,
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            px: 0,
            height: "100%",
          }}
        >
          <img
            src={logo_140_160}
            style={{ ...logoStyle, userSelect: "none" }}
            alt="Logo"
            onClick={() => nav(routesList.homepage)}
            draggable={false}
          />
          <Typography
            noWrap
            variant="h6"
            component="span"
            onClick={() => nav(routesList.homepage)}
            sx={{
              display: { sm: "block" },
              userSelect: "none",
              pr: "16px",
              cursor: "pointer",
              color: "white",
              fontStyle: "bold",
            }}
          >
            Videomobile SHPK
          </Typography>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <MenuItem
              onClick={handleClick}
              sx={{
                py: "6px",
                px: "16px",
                "&:hover": {
                  backgroundColor: "transparent", // Removes the default hover background
                },
              }}
              disableRipple
            >
              <Typography variant="body2" color="white">
                Contact Us
              </Typography>
            </MenuItem>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleEmail}>info@videomobile.al</MenuItem>
              <MenuItem onClick={handlePhone}>(+355) 67 600-0808</MenuItem>
              <MenuItem onClick={handleInstagram}>@videomobile_al</MenuItem>
            </Menu>
          </Box>
        </Box>

        {!isMobile && (
          <Box width={300}>
            <VirtualizedSearchBar />
          </Box>
        )}
        {isMobile && (
          <IconButton
            onClick={() => {
              window.location.href = "tel:+355676000808";
            }}
          >
            <CallIcon sx={{ color: "white" }} />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};
