import { Box, Grid2, Typography, Link as MuiLink } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";

export const Footer = () => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "primary.dark",
        color: "white",
        mt: 2,
        p: 4,
        pb: 7,
      }}
    >
      <Grid2 container spacing={4}>
        <Grid2 size={{ xs: 12, sm: 4 }}>
          <Typography variant="h6" gutterBottom>
            Videomobile SHPK
          </Typography>
          <Typography variant="body2">
            Welcome to Videomobile, your premier destination for top-tier
            cameras, lighting, audio, and production equipment from leading
            brands like Panasonic, Canon, Nikon, Sennheiser, Blueshape, LiveU,
            Blackmagic Design, Datavideo, and more. Whether you're a
            professional filmmaker, videographer, or content creator, we provide
            the essential tools and expert advice to help you achieve your
            creative vision. Discover quality products, competitive prices, and
            unparalleled customer support at Videomobile. Elevate your
            production with us!
          </Typography>
        </Grid2>
        <Grid2
          size={{ xs: 12, sm: 4 }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ mx: "auto" }}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <MuiLink
              href="https://www.instagram.com/videomobile_al?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                color: "inherit",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              <InstagramIcon />
              <span style={{ marginLeft: "8px" }}>@videomobile_al</span>
            </MuiLink>
            <br />
            <MuiLink
              href="https://www.facebook.com/share/TWnB9k73pdDj6Mry/?mibextid=LQQJ4d"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                color: "inherit",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              <FacebookIcon />
              <span style={{ marginLeft: "8px" }}>@videomobile shpk</span>
            </MuiLink>
            <br />
            <MuiLink
              href="https://www.youtube.com/@videomobileAL"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                color: "inherit",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              <YouTubeIcon />
              <span style={{ marginLeft: "8px" }}>@videomobileAL</span>
            </MuiLink>
          </Box>
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 4 }}>
          <Typography variant="h6" gutterBottom>
            Contact Info
          </Typography>
          <Typography variant="body2">
            <a
              href="https://www.google.com/maps/place/Videomobile+shpk/@41.3195988,19.7836083,17z/data=!3m1!4b1!4m6!3m5!1s0x135031ef0d1e971d:0xb2f92f92d162037a!8m2!3d41.3195988!4d19.7861832!16s%2Fg%2F11g0s_lyj7?entry=ttu&g_ep=EgoyMDI0MTAxNi4wIKXMDSoASAFQAw%3D%3D"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.textDecoration = "underline")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.textDecoration = "none")
              }
            >
              Address: Rruga Bedri Karapici, Tirana, Albania
            </a>
          </Typography>
          <Typography
            variant="body2"
            onMouseEnter={(e) =>
              (e.currentTarget.style.textDecoration = "underline")
            }
            onMouseOut={(e) => (e.currentTarget.style.textDecoration = "none")}
          >
            <a
              href="tel:+355676000808"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Phone: (+355) 67 600-0808
            </a>
          </Typography>
          <Typography
            variant="body2"
            onMouseEnter={(e) =>
              (e.currentTarget.style.textDecoration = "underline")
            }
            onMouseOut={(e) => (e.currentTarget.style.textDecoration = "none")}
          >
            <a
              href="mailto:info@videomobile.al"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Email: info@videomobile.al
            </a>
          </Typography>
        </Grid2>
      </Grid2>
      <Box
        sx={{
          textAlign: "center",
          mt: 4,
          pt: 2,
          borderTop: "1px solid rgba(255, 255, 255, 0.2)",
        }}
      >
        <Typography variant="body2">
          © {new Date().getFullYear()} Videomobile.al. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};
