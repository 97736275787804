import React from "react";
import Carousel from "react-material-ui-carousel";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { deserializeFilterUrlParams } from "../../utility/utility";
import { setFilters, Status } from "../../store/slices/productSlice";
import { URL } from "url";
import { Box, Skeleton } from "@mui/material";

interface ISlideshowProps {
  scrollProductPageIntoView: () => void;
}

export const Slideshow = ({ scrollProductPageIntoView }: ISlideshowProps) => {
  const slideshows = useAppSelector((state) => state.slideshows);
  const dispatch = useAppDispatch();

  return slideshows.dataState === Status.Fulfilled ? (
    slideshows.list.length > 0 ? (
      <Carousel
        animation="fade"
        stopAutoPlayOnHover={false}
        interval={5000}
        indicators={true}
        swipe={false} //isMobile? true
        sx={{
          width: "100%",
          aspectRatio: "16/5",
        }}
        indicatorContainerProps={{
          style: {
            zIndex: 1,
            marginTop: "-30px",
            position: "relative",
          },
        }}
      >
        {slideshows.dataState === Status.Fulfilled ? (
          slideshows.list.map((slideshow, i) => (
            <img
              key={slideshow.id}
              src={slideshow.pictureUrl}
              alt={"Slideshow could not load"}
              draggable={false}
              onClick={() => {
                if (slideshow.urlToNavigateTo) {
                  const searchParams = new URLSearchParams(
                    slideshow.urlToNavigateTo.split("?")[1]
                  );
                  const {
                    brandIds,
                    minPrice,
                    maxPrice,
                    comingSoon,
                    onSale,
                    productTypeIds,
                    newProducts,
                  } = deserializeFilterUrlParams(searchParams);

                  console.log(Array.from(searchParams.entries()));

                  dispatch(
                    setFilters({
                      checkedBrands: brandIds,
                      checkedProductTypes: productTypeIds,
                      minPrice: minPrice,
                      maxPrice: maxPrice,
                      onSale: onSale,
                      comingSoon: comingSoon,
                      newProducts: newProducts,
                    })
                  );
                  scrollProductPageIntoView();
                }
              }}
              style={{
                width: "100%",
                aspectRatio: "16/5",
                userSelect: "none",
                cursor: slideshow.urlToNavigateTo ? "pointer" : "default",
              }}
            />
          ))
        ) : (
          <></>
        )}
      </Carousel>
    ) : (
      <></>
    )
  ) : (
    <Box sx={{ width: "100%", aspectRatio: "16/5" }}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ width: "100%", height: "100%" }}
      />
    </Box>
  );
};
