import { ThemeProvider } from "@mui/material";
import "./App.css";
import AppContent from "./AppContent";
import { theme as VideomobileTheme } from "./styles";
import { MobileProvider } from "./MobileProvider";

function App() {
  return (
    <ThemeProvider theme={VideomobileTheme}>
      <MobileProvider>
        <AppContent />
      </MobileProvider>
    </ThemeProvider>
  );
}

export default App;
