import { useMediaQuery, useTheme } from "@mui/material";
import { createContext, ReactNode, useContext } from "react";

interface IMobileProviderProps {
  children: ReactNode;
}

const MobileContext = createContext<boolean>(false);

export const MobileProvider = ({ children }: IMobileProviderProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <MobileContext.Provider value={isMobile}>{children}</MobileContext.Provider>
  );
};

export const useMobile = () => {
  return useContext(MobileContext);
};
