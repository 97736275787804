import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Slider,
  Switch,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import {
  changePriceFilter,
  checkBrandFilter,
  checkComingSoonFilter,
  checkProductType,
  checkOnSaleFilter,
  openOrCloseFilter,
  resetFilters,
  checkNewProductsFilter,
} from "../../store/slices/productSlice";
import { calculateMinMaxPrice } from "../../utility/utility";

export const Sidebar = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state) => state.filters);
  const [tempPriceValues, SetTempPirceValues] = useState<string[]>([
    filters.price.data.minPrice.toString(),
    filters.price.data.maxPrice.toString(),
  ]);

  const minValue = tempPriceValues[0] === "" ? 0 : Number(tempPriceValues[0]);
  const maxValue =
    tempPriceValues[1] === ""
      ? filters.price.data.maxPrice
      : Number(tempPriceValues[1]);

  useEffect(() => {
    SetTempPirceValues([
      filters.price.data.minPrice.toString(),
      filters.price.data.maxPrice.toString(),
    ]);
  }, [filters.price.data.minPrice, filters.price.data.maxPrice]);

  const brandCheckboxes = useMemo(() => {
    return (
      <FormGroup sx={{ pl: 4 }}>
        {filters.brand.data.map((filter) => {
          return (
            <FormControlLabel
              key={filter.brand.id}
              control={<Checkbox />}
              label={filter.brand.name}
              checked={filter.isChecked}
              onChange={() =>
                dispatch(
                  checkBrandFilter({
                    id: filter.brand.id,
                    isChecked: !filter.isChecked,
                  })
                )
              }
            />
          );
        })}
      </FormGroup>
    );
  }, [dispatch, filters.brand.data]);

  const productTypeCheckBoxes = useMemo(() => {
    return (
      <FormGroup sx={{ pl: 4 }}>
        {filters.productType.data.map((filter) => {
          return (
            <FormControlLabel
              key={filter.productType.name}
              control={<Checkbox />}
              label={filter.productType.name}
              checked={filter.isChecked}
              onChange={() =>
                dispatch(
                  checkProductType({
                    productType: filter.productType,
                    isChecked: !filter.isChecked,
                  })
                )
              }
            />
          );
        })}
      </FormGroup>
    );
  }, [dispatch, filters.productType.data]);

  const priceOnBlur = useCallback(() => {
    const { minPrice, maxPrice } = calculateMinMaxPrice(minValue, maxValue);

    SetTempPirceValues([minPrice.toString(), maxPrice.toString()]);
    dispatch(changePriceFilter({ minPrice: minPrice, maxPrice: maxPrice }));
  }, [dispatch, maxValue, minValue]);

  const handleSliderChange = useCallback(
    (_: any, newValue: number | number[]) => {
      if (typeof newValue !== "number") {
        SetTempPirceValues([newValue[0].toString(), newValue[1].toString()]);
      }
    },
    []
  );

  const handleSliderCommited = useCallback(
    (_: any, newValue: number | number[]) => {
      if (typeof newValue !== "number") {
        dispatch(
          changePriceFilter({ minPrice: newValue[0], maxPrice: newValue[1] })
        );
      }
    },
    [dispatch]
  );

  const priceRange = useMemo(() => {
    return (
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <TextField
            size="small"
            type="number"
            label="Min"
            onBlur={() => {
              priceOnBlur();
            }}
            onChange={(e) => {
              SetTempPirceValues([e.target.value, tempPriceValues[1]]);
            }}
            value={tempPriceValues[0]}
            inputProps={{ min: 0, max: 20000 }}
          />
          <TextField
            size="small"
            type="number"
            label="Max"
            onBlur={() => {
              priceOnBlur();
            }}
            onChange={(e) => {
              SetTempPirceValues([tempPriceValues[0], e.target.value]);
            }}
            value={tempPriceValues[1]}
            inputProps={{ min: 0, max: 20000 }}
          />
        </Box>
        <Slider
          value={[minValue, maxValue]}
          min={0}
          max={20000}
          valueLabelDisplay="auto"
          onChange={handleSliderChange}
          sx={{ width: "95%" }}
          step={10}
          onChangeCommitted={handleSliderCommited}
        />
      </Box>
    );
  }, [
    handleSliderChange,
    handleSliderCommited,
    maxValue,
    minValue,
    priceOnBlur,
    tempPriceValues,
  ]);

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        minWidth: 200,
        flexShrink: 0,
        width: "100%",
        overflowY: "auto",
        scrollbarGutter: "stable",
      }}
    >
      <List
        subheader={
          <ListSubheader
            component={"div"}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            Filters
            <Button
              // variant="outlined"
              size="small"
              onClick={() => dispatch(resetFilters())}
              sx={{ textTransform: "none", ml: 2 }}
            >
              Reset Filters
            </Button>
          </ListSubheader>
        }
      >
        <ListItemButton
          onClick={() => dispatch(checkOnSaleFilter(!filters.onSale.data))}
        >
          <ListItemText primary={filters.onSale.name} />
          <Switch edge="end" checked={filters.onSale.data} />
        </ListItemButton>
        <ListItemButton
          onClick={() =>
            dispatch(checkComingSoonFilter(!filters.comingSoon.data))
          }
        >
          <ListItemText primary={filters.comingSoon.name} />
          <Switch edge="end" checked={filters.comingSoon.data} />
        </ListItemButton>
        <ListItemButton
          onClick={() =>
            dispatch(checkNewProductsFilter(!filters.newProducts.data))
          }
        >
          <ListItemText primary={filters.newProducts.name} />
          <Switch edge="end" checked={filters.newProducts.data} />
        </ListItemButton>
        <ListItemButton onClick={() => dispatch(openOrCloseFilter("price"))}>
          <ListItemText primary={filters.price.name} />
          {filters.price.isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={filters.price.isOpen} timeout="auto" unmountOnExit>
          {priceRange}
        </Collapse>
        <ListItemButton
          onClick={() => dispatch(openOrCloseFilter("productType"))}
        >
          <ListItemText primary={filters.productType.name} />
          {filters.productType.isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={filters.productType.isOpen} timeout="auto" unmountOnExit>
          {productTypeCheckBoxes}
        </Collapse>
        <ListItemButton onClick={() => dispatch(openOrCloseFilter("brand"))}>
          <ListItemText primary={filters.brand.name} />
          {filters.brand.isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={filters.brand.isOpen} timeout="auto" unmountOnExit>
          {brandCheckboxes}
        </Collapse>
      </List>
    </Box>
  );
};
